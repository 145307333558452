import React from 'react';
import './Header.css';
import houseImage from "./../img/house-big.svg";
import pharaonImage from "./../img/17.png";

const OurGames = () => {
    return (
        <div className='game-row'>
            <div className='game-column'>
                <p className='game-header'>THE HOUSE</p>
                <img className='game-image' src={houseImage}/>
                <p className='game-text'>Démoni obsadili starý
        dom. Tvojou úlohou nie je
        pred nimi utiecť, práve
        naopak, nájsť ich, pretože
        len oni ti ukážu cestu von.</p>
            </div>
            <div className='game-column'>
                <p className='game-header'>KLIATBA FARAÓNA</p>
                <img className='game-image' src={pharaonImage}/>
                <p className='game-text'>Zatiaľ nedotknutá hrobka
        faraóna čaká na teba, aby
        si odhalil jej tajomstvá,
        zrušil kliatbu a objavil
        poklady, ktoré ukrýva.</p>
            </div>
        </div>
    )
}

export default OurGames;