import React from "react";
import './Header.css';
import logo from "./../img/escape-room-logo-RK.svg";
import { Link, NavLink } from "react-router-dom";

export default class Header extends React.Component {
    render() {
      return (
        <div>
            <img src={logo} class="logo"/>
            <p class="introtext">jedinečná zábava s mrazivou atmosférou. Nájdi cestu von...</p>
            <ul className="App-header navigation">
                <li>
                    <NavLink exact className="App-header-item" activeClassName="active" to="/"><h1>O hre</h1></NavLink>
                </li>
                <li>
                    <NavLink exact className="App-header-item" activeClassName="active" to="/ourgames"><h1>Naše hry</h1></NavLink>
                </li>
                <li>
                    <NavLink exact className="App-header-item" activeClassName="active" to="/price"><h1>Cenník</h1></NavLink>
                </li>
                <li>
                    <NavLink exact className="App-header-item" activeClassName="active" to="/contact"><h1>Kontakt</h1></NavLink>
                </li>
                <li>
                    <NavLink exact className="App-header-item" activeClassName="active" to="/coupon"><h1>Daruj hru</h1></NavLink>
                </li>
            </ul>
        </div>
      );
    }
  }