import React from 'react';
import pricelist from "./../img/current-pricing.png";

const Price = () =>{
    return (
        <div>
            <img src={pricelist}/>
        </div>);    
}

export default Price;