import React from 'react';
import contactImage from "./../img/house-contact.svg";

const Contact = () =>{
    return (
        <div>
            <img src={contactImage}/>
        </div>);    
}

export default Contact;