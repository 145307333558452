import React from 'react';
import homeImage from "./../img/about-game.svg";

const Home = () => {
    return (
        <div>
            <img src={homeImage}/>
        </div>);
}

export default Home;