import React, { useRef, useState } from "react";
import emailjs from 'emailjs-com';
import './Header.css';
import ReCAPTCHA from "react-google-recaptcha";

export const Coupon = () => {
  const form = useRef();
  let captcha = true;

  const [userRequest, setUserRequest] = useState({
    isFormVisible: true,
    isSuccessVisible: false,
    isFailVisible: false,
    buttonDisabled: true
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_a80557u', 'template_arpdk9a', form.current, 'user_7pl6d2abHinzMob51G9hj')
      .then((result) => {
          console.log(result.text);
          setUserRequest({isFailVisible: false, isFormVisible: false, isSuccessVisible: true});
      }, (error) => {
          console.log(error.text);
          setUserRequest({isFailVisible: true, isFormVisible: false, isSuccessVisible: false});
      });
  };

  const onChange = (value) => {
    setUserRequest({...userRequest, buttonDisabled: false})
  }  

  return (
    <div>
      {userRequest.isSuccessVisible && <label class='label-text'>Objednávka odoslaná.</label>}
      {userRequest.isFailVisible && <label class='label-text'>Objednávka prijatá.</label>}
      {userRequest.isFormVisible && 
      <div>
        <label class='label-text'>Po objednaní darčekového poukazu budete kontaktovaní na uvedenú mailovú adresu, kde Vám budú zaslané potrebné informácie ohľadom platby. Darčekový poukaz posielame elektronicky. Cena poukazu je 53 eur.</label>
        <div>
          <form ref={form} onSubmit={sendEmail}>
            <div className="input-row">
              <label>Meno</label>
              <input type="text" name="user_name" required/>
            </div>
            <div className="input-row">
              <label>Email</label>
              <input type="email" name="user_email" required/>
            </div>
            <div className="input-row">
              <label>Telefón</label>
              <input type="tel" name="user_phone" required/>
            </div>
            <div className="input-row">
              <label>Adresa</label>
              <input name="message" required/>
            </div>
            <div className="input-row">
              <label>Počet kupónov</label>
              <input type="number" name="coupon_number" required/>
            </div>
            <div className="flex-input-row">
              <input type="checkbox" name="check" required/>
              <label className="small-label-text">Kliknutím na "Objednať" dávam dobrovoľný súhlas so spracovaním osobných údajov v rozsahu: meno, e-mailová adresa a telefónny kontakt za účelom spätnej komunikácie, po dobu nevyhnutne potrebnú (maximálne 5 rokov).</label>
            </div>
            <ReCAPTCHA class='recaptcha'
              sitekey="6LcbhnAdAAAAAIHxoH3pIWxLc3G4PnzcL3AkMClj"
              onChange={onChange}/>
            <input type="submit" value="Objednať"  disabled={userRequest.buttonDisabled}/>
          </form>
        </div>
      </div>}
    </div>
  );
};