import React from "react";
import Header from "./Header";
import bottomImage from "./../img/house.svg";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "./Home";
import Price from "./Price";
import Contact from "./Contact";
import { Coupon } from "./Coupon";
import OurGames from "./OurGames";

export default class App extends React.Component {
  render(){
    return (
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/ourgames" element={<OurGames />}></Route>
          <Route exact path="/price" element={<Price />}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route exact path="/coupon" element={<Coupon />}></Route>          
        </Routes>
      </BrowserRouter>
    );
  }
}